/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap"); */
@import url("//cdn.web-fonts.ge/fonts/bpg-arial/css/bpg-arial.min.css");

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-Regular"),
    url("./Assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* html, body, #root, .app, .content {
  height: 100%;
  width: 100%;
} */

body {
  font-family: 'BPG Arial', 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #042045; */
}

/* .app {
  display: flex;
  position: relative;
} */

ul {
  list-style: none;
}

li {
  text-decoration: none;
  list-style: none;
}

a {
  text-decoration: none;
}

input, option, button {
  font-family: 'Montserrat', sans-serif;
}

/*-------------- START SCROLLBAR --------------*/
/* for mozilla scrollbar start */
:root {
  scrollbar-color: #fff #042045;
  scrollbar-width: thin;
}
/* for mozilla scrollbar end */
::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-track {
  background: #042045;
}
::-webkit-scrollbar-thumb {
  border: 4px solid #042045;
  border-radius: 8px;
  background: #fff;
}
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
/*-------------- END SCROLLBAR --------------*/
